import { useMediaQuery } from '@kaliber/use-media-query'

import { useTranslate } from '/machinery/I18n'

import { MediaWindow } from '/features/pageOnly/MediaWindow'

import mediaStyles from '/cssGlobal/media.css'
import styles from './OverviewHero.css'

export function OverviewHero({ heroImage }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  return (
    <div data-x='hero' className={styles.component}>
      <Title layoutClassName={styles.titleLayout} />
      <MediaWindow image={heroImage} aspectRatio={isViewportLg ? 3 / 2 : 2 / 3} distanceOverride={50} layoutClassName={styles.imageLayout} />
    </div>
  )
}

function Title({ layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <h1 className={cx(styles.componentTitle, layoutClassName)}>
      {__`frequently`}<br />
      <span className={styles.titleHighlight}>
        {__`asked-questions`}
      </span>
    </h1>
  )
}
